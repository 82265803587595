$color-text-high-emphasize: #ffffff;
$color-text-medium-emphasize: rgba(223, 226, 234, 0.84);
$color-text-low-emphasize: rgba(223, 226, 234, 0.42);

$color-gold: #fbdcb8;

$color-background-page: #05070d;
$color-background-panel: #0d111e;
$color-border: #101d2a;

$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";

// Responsive breakpoints
$screen-sm-min: 576px; // Small tablets (portrait view)
$screen-md-min: 768px; // Tablets and small desktops
$screen-lg-min: 992px; // Large tablets and desktops
