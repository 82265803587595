#our-technologies {
  padding: 160px 0;

  h2 {
    text-align: center;
  }

  // padding: 120px 0;

  // .content {
  //   grid-column: 6 / 13;
  // }
}
