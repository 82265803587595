#topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .container {
    height: 24px;
    margin-top: 32px;
    align-items: center;

    @include md {
      margin-top: 48px;
    }

    @include lg {
      margin-top: 56px;
    }
  }

  nav {
    grid-column: 4 / 13;
    text-align: right;
    display: none;

    @include md {
      display: block;
    }

    .nav__list {
      margin: 0;
      padding: 0;
      list-style: none;
      float: right;

      &:last-child {
        margin-right: 0;
      }
    }

    .nav__item {
      float: left;
      margin-left: 16px;
      margin-right: 16px;
    }

    .nav__link {
      color: $color-text-high-emphasize;
      text-decoration: none;

      &:hover {
        color: $color-gold;
      }
    }
  }
}
