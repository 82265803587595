@font-face {
  font-family: "Manrope";
  font-weight: 400;
  src: url("../assets/fonts/Manrope-Regular.woff2") format("woff2"),
    url("../assets/fonts/Manrope-Regular.woff") format("woff");
}

@font-face {
  font-family: "Manrope";
  font-weight: 600;
  src: url("../assets/fonts/Manrope-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Manrope-ExtraBold.woff") format("woff");
}
