@use "sass:map";

html {
  font-size: 16px;

  @include md {
    font-size: 18px;
  }
}

body {
  font-size: 1rem;
  line-height: 1.6;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  line-height: 1.125;
  color: $color-text-high-emphasize;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 2rem;

  @include md {
    font-size: 2.88rem;
  }

  @include lg {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2rem;

  @include md {
    font-size: 2.22rem;
  }

  @include lg {
    font-size: 2.66rem;
  }
}

h3 {
  font-size: 1.5rem;

  @include md {
    font-size: 1.77rem;
  }

  @include lg {
    font-size: 1.88rem;
  }
}

h4 {
  font-size: 1.2rem;

  @include md {
    font-size: 1.33rem;
  }

  @include lg {
    font-size: 1.5rem;
  }
}

.large {
  font-size: 1rem;

  @include lg {
    font-size: 1.2rem;
  }
}

.small {
  font-size: 0.777rem;
}

.text-center {
  text-align: center;
}
