.card {
  background: $color-background-panel;
  display: flex;
  grid-column: span 6;

  margin: 1rem -1rem;
  @include md {
    margin-left: 0;
    margin-right: 0;
  }
}

.card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.card__info {
  box-sizing: border-box;
  padding: 24px 16px;

  @include md {
    padding: 56px;
  }
}

.card__image {
  background: #34ba7d;
  height: 250px;

  @include md {
    height: 100%;
  }
}

.card--horizontal {
  grid-column: 1/-1;
  display: grid;
  grid-auto-flow: dense;

  @include md {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;

    .card__info {
      grid-column: 1 / 1;
    }

    .card__image {
      grid-column: 2 / 3;
    }
  }
}

.card__logo {
  @extend.small;
  font-weight: 600;
  background-repeat: no-repeat;
  height: 40px;
  line-height: 40px;
  padding-left: 56px;
}

.card__title {
  margin-bottom: 1.5rem;
}

.card__action {
  display: inline-block;
  margin-top: 24px;
  color: #fff;
  font-weight: 600;
  text-decoration: none;

  &:hover svg {
    transform: translateX(4px);
  }

  svg {
    margin-left: 4px;
    transition: transform 0.2s 0s ease;
  }

  &:hover {
    color: $color-gold;
  }
}

.pill {
  border: 1px solid rgba(178, 182, 202, 0.45);
  padding: 4px 12px;
  height: 22px;
  font-weight: 600;
  font-size: 11px;
  display: flex;
  border-radius: 12px;
  padding: 0 10px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;

  color: #cacee3;
}

#plagiarism-check {
  .card__logo {
    background-image: url("/assets/img/plagiarism-check-icon.svg");
    color: #34ba7d;
  }

  .card__image {
    background-color: #34ba7d;
    background-image: url("/assets/img/plagiarism-check-image.png");
    @media #{$retina} {
      background-image: url("/assets/img/plagiarism-check-image@2x.png");
    }
    background-size: cover;
    background-position: left top;
  }
}

#write-assist .card__logo {
  background-image: url("/assets/img/write-assist-icon.svg");
  color: #5f7fd3;
}

#reference-check .card__logo {
  background-image: url("/assets/img/reference-check-icon.svg");
  color: #58aec7;
}
