.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1252px;
  padding: 0 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include md {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
  }
}
