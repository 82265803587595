#header {
  height: 95vh;
  position: relative;

  .container {
    height: 100%;
    align-items: center;
  }
  .content {
    z-index: 100;

    @include md {
      grid-column: 1 / 8;
    }

    @include lg {
      grid-column: 1 / 7;
    }
  }

  .intro {
    margin-bottom: 32px;
  }

  border-bottom: 1px solid $color-border;
}

#visual {
  z-index: 0;
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
}
