#contact {
  padding: 120px 0;

  h2 {
    text-align: center;
  }

  .subtitle {
    text-align: center;
    max-width: 548px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-container {
    margin-top: 54px;

    @include md {
      grid-column: 4 / 10;
    }
  }

  .form {
    width: 100%;
  }

  button {
    margin: 0 auto;
    display: block;
  }
}

#contact-form.hidden {
  display: none;
}

#contact-form-confirmation {
  border: 1px solid #292e3d;
  padding: 56px;
  text-align: center;
  display: none;

  p {
    margin-bottom: 1rem;
  }

  &.visible {
    display: block;
  }
}
