#about-us {
  padding: 160px 0;

  .leading {
    @extend .small;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-bottom: 24px;
    color: $color-text-low-emphasize;
  }

  .container {
    background-image: url("/assets/img/aboutus-background.png");
    @media #{$retina} {
      background-image: url("/assets/img/aboutus-background@2x.png");
      background-size: 75%;
    }
    background-position-y: center;
    background-repeat: no-repeat;
  }

  .content {
    grid-column: 7 / 13;
  }
}
