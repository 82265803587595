html,
body {
  padding: 0;
  margin: 0;
  background: $color-background-page;
}

body {
  color: $color-text-medium-emphasize;

  @include md {
  }

  @include lg {
  }
}

a {
  color: $color-gold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
