input,
textarea {
  width: 100%;
  font-size: 1rem;
  border: 1px solid #292e3d;
  border-radius: 2px;
  background: none;
  padding: 16px;
  color: $color-text-high-emphasize;
  box-sizing: border-box;

  &::placeholder {
    color: $color-text-low-emphasize;
  }

  &:focus {
    background: #0d111e;
    border-color: #7393eb;
  }
}

textarea {
  min-height: 200px;
  resize: vertical;
}

label {
  @extend .small;
  color: $color-text-medium-emphasize;
}

.form__row {
  margin: 1.8rem 0;
}

.form__input--error {
  background-color: #1a0401;
  border-color: #782115;

  &::placeholder {
    color: #df4d39;
  }
}

.form__error-message {
  @extend .small;
  color: #df4d39;
  margin-top: 4px;
}

button[type="submit"],
.button {
  color: $color-gold;
  font-weight: 700;
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  background: none;
  border: 2px solid $color-gold;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background 0.15s 0s ease;

  &:hover,
  &:focus {
    background: #231403;
  }
}

/*
 * Floating label
 */
.floating-label {
  position: relative;

  .form__label {
    position: absolute;
    top: -2px; // label margin bottom
    transition: transform 0.2s;
    transform: translateY(-80%);

    // hide
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
  }

  .form__label--floating {
    clip: auto;
    clip-path: none;
    transform: translateY(-100%);
  }
}
