#page-404 .content {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-bottom: 8px;
  }

  .button {
    margin-top: 24px;
  }
}
